import React, {useLayoutEffect, useState} from 'react';
import {Alert, Button, Checkbox, Col, Form, Input, Layout, Row} from 'antd';
import CreateUserModal from "./createUser"
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {session} from "../../../service/session";
import {launchApp} from "../../../index";
import ForgotPasswordModal from "./forgotPassword";
import {AuthenticationResult} from '../../../service/domain/authenticationResult';
import styled from 'styled-components'

const {Content, Footer} = Layout;

const contentStyles = {
    backgroundColor: 'rgb(0, 0, 0, 1)',
    height: "calc(100vh - 70px)",
    fontFamily: 'AvenirNext-Medium,Arial,Sans-Serif',
}

const loginWindowStyles = {
    width:'350px',
    backgroundColor: 'rgb(0, 0, 0, 1)',
    padding: '30px 30px 0px 30px',
    borderRadius: 4,
    borderColor: 'rgb(220, 136, 174, 1)',
    borderWidth: 2,
    borderStyle: 'solid',
}

// Style the login button
const StyledButton = styled(Button)`
  background-color: #000;
  width: 100%;
  margin-bottom: 12px;
  color: #fff;
  border-color: rgb(220, 136, 174, 1);
  border-radius: 0px;
  height: 40px;
  &:hover {
    border-color: rgb(220, 136, 174, 1);
    background-color: rgb(220, 136, 174) !important;
    color: black;
  }
`;



const LoginPage = () => {

    // Show alert for invalid password
    const [loginStatus, setLoginStatus] = React.useState(AuthenticationResult.PENDING)
    const [disableLoginButton, setDisableLoginButton] = React.useState(false)

    const InvalidPasswordAlert = () => {
        return (
            <>
                {loginStatus !== AuthenticationResult.PENDING && loginStatus !== AuthenticationResult.SUCCESS ? <Form.Item><Alert message={loginStatus} type={"error"} showIcon={true} onClose={() => setLoginStatus(AuthenticationResult.PENDING)} closable={true}/></Form.Item> : null}
            </>
        )
    }

    const onFinish = (values: any) => {
        setDisableLoginButton(true)
        setTimeout(function(){setDisableLoginButton(false)},5000) // hard timeout of 5 seconds to open log in button
        setLoginStatus(AuthenticationResult.PENDING)
        session.authenticateNewSession(values.email, values.password, values.remember, status => {
            if (status === AuthenticationResult.SUCCESS) {
                setDisableLoginButton(false)
                // TODO - broadcast to all other tabs which are open to the URL
                launchApp()
            } else {
                setDisableLoginButton(false)
                setLoginStatus(status)
            }
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        setDisableLoginButton(false)
        setLoginStatus(AuthenticationResult.PENDING)
    };

    // Listen to window resizing so can update layout properly on resize
    const [windowWidth, setWindowWidth] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <Layout>
            <Content style={contentStyles}>
                <Row justify="center" align="middle" style={{minHeight: 'calc(100vh - 25%)'}}>
                    <Col>

                        <Form style={loginWindowStyles}
                              name="basic"
                              initialValues={{remember: false}}
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}
                        >

                            <Row justify="center"  style={{marginBottom: '30px'}}>
                                <a href="https://jooni.com" target="_blank" rel="noreferrer">
                                    <img style={windowWidth > 600 ? {maxWidth: '90px'} : {maxWidth: '60px'}} src={'https://storage.googleapis.com/jooni-logos/Logo%20Ghetto.png'} className="App-logo" alt="logo" />
                                </a>
                            </Row>

                            <Form.Item
                                name="email"
                                rules={[{required: true, message: 'Please enter email'}]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={'Email'} onInput={()=>setDisableLoginButton(false)}
                                       style={{borderRadius: 0, height: 40}}
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{required: true, message: 'Please enter password'}]}
                                style={{marginBottom:'6px'}}
                            >
                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={'Password'} onInput={()=>setDisableLoginButton(false)}
                                        style={{borderRadius: 0, height: 40}}
                                />

                            </Form.Item>

                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox style={{color: "white"}}>Remember me</Checkbox>
                                </Form.Item>
                                <ForgotPasswordModal/>
                            </Form.Item>

                            <InvalidPasswordAlert/>

                            <Form.Item style={{color: "white"}}>
                                <StyledButton type="default" disabled={disableLoginButton} htmlType="submit">
                                    LOG IN
                                </StyledButton>
                                <br/>
                                {/*Or <CreateUserModal/>*/}
                            </Form.Item>

                        </Form>

                    </Col>
                </Row>

            </Content>

            <Footer style={{ textAlign: 'center',
                backgroundColor: 'rgb(0, 0, 0, 1)',
                fontSize: '14px',
                fontFamily: 'AvenirNext-Medium,Arial,Sans-Serif',
                color: '#565a5c'}}
            >Copyright 2021 Jooni Inc. All rights reserved.</Footer>

        </Layout>
    );
};

export default LoginPage