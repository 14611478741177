import { api } from "../apiClient";
import {session} from "../session";
import {map, mergeMap} from "rxjs/operators";
import {Observable, of, Subscription, timer} from "rxjs";
import {View, ViewId} from "../domain/view";

class ViewManager {

    private viewId?: number;

    /**
     * Get what the current view id is for the window/tab is
     */
    public getViewId(): Observable<number> {
        this.ensureKeepAliveStarted()
        // TODO: #3 - check and ensure view id in local storage is proper. handle multiple requests at once as well
        if (this.viewId !== undefined) {
            return of(this.viewId)
        }
        // retrieve the view id from session storage on a page refresh
        else if (window.sessionStorage.getItem("viewId") !== null) {
            this.viewId = Number(window.sessionStorage.getItem("viewId"))
            return of(this.viewId)
        }
        return api.sendRequest<ViewId>("getNextViewId", {sessionAuthToken: session.getSessionAuthToken()})
            .pipe(map((viewIds) => {
                // TODO: #3 - ensure retrieved view id is not in local storage
                this.viewId = viewIds.viewId
                // store the view number in a window.sessionStorage("view", "<view id>") so it persists across page refreshes but is unique to each tab
                window.sessionStorage.setItem("viewId", String(this.viewId))
                return this.viewId
            }))
    }

    /**
     * ensure that my current view is kept alive and not given to a different window/tab. If the network was disconnected
     * and the view was removed on the server, this will also tell the server that this view id is being used by this window/tab
     */
    public keepViewIdAlive(): Observable<boolean> {
        return viewManager.getViewId()
            .pipe(mergeMap((view) => {
                return api.sendRequest<boolean>("pingViewId", {sessionAuthToken: session.getSessionAuthToken(), viewId: view})
            }))
    }

    /**
     * Tell the server this window/tab is done with the view id. Calling this on closing will allow the id to be reopened
     * immediately in a new window
     */
    public killViewId() {
        this.ensureKeepAliveStopped()

        return viewManager.getViewId()
            .subscribe((view) => {
                // Tell the server to kill the view, but keep the session storage value so a refresh will
                // see the old view id when it reloads
                // TODO: #3 - remove from the local storage
                return api.sendBeacon("killViewId", {sessionAuthToken: session.getSessionAuthToken(), viewId: view})
            })
    }

    /**
     * retrieve the current view for the given static fields. Static fields should include information like the current
     * page or other information used to indicate what is being looked at. The view information retrieve could be any
     * dynamic state set by the user
     * @param staticFields - information that indicates what page is currently being looked at
     */
    public getView(staticFields : object = {}): Observable<View> {
        return viewManager.getViewId()
            .pipe(mergeMap(viewId => {
                return api.sendRequest<View>("getView", {
                        sessionAuthToken: session.getSessionAuthToken(),
                        viewId: viewId,
                        staticFields: staticFields
                    })
            }))
    }

    /**
     * Tell the server to save the dynamic fields/settings for the given static fields. Every time a dynamic field is adjusted
     * the information should be sent to the server so any refresh/reload will bring the page to the same state
     *
     * @param staticFields - information that indicates what page is currently being looked at
     * @param dynamicFields - information/settings that can change by the user and wants to be recreated if returning to the page
     */
    public updateView(staticFields: object = {}, dynamicFields: object = {}): Observable<View> {
        return viewManager.getViewId()
            .pipe(mergeMap((viewId) => {
                return api.sendRequest<View>("updateView", {
                        sessionAuthToken: session.getSessionAuthToken(),
                        viewId: viewId,
                        staticFields: staticFields,
                        dynamicFields: dynamicFields,
                        time: new Date().toISOString()
                    })
            }))
    }

    // ensure a view is kept alive and able to stop the keep alive when logged out or invalid authentication token
    private subscription ?: Subscription
    public ensureKeepAliveStarted() {
        if (this.subscription === undefined)
            this.subscription = timer(0, 7000)
                .subscribe(_ => viewManager.keepViewIdAlive().subscribe());
    }
    public ensureKeepAliveStopped() {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
            this.subscription = undefined
        }
    }

    private static singletonInstance: ViewManager

    public static get Instance() {
        // on window close/refresh tell the server the window is done with the view id. On refresh, the view id stored
        // in session storage will continue the tab/window with the same view id
        window.addEventListener("unload", (ev) => {
            viewManager.killViewId()
        })

        return this.singletonInstance || (this.singletonInstance = new this())
    }

}

export const viewManager = ViewManager.Instance