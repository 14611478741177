import React, {useEffect, useRef, useState, useCallback} from "react";
import {Input, message} from "antd";
import {useHistory, useLocation} from "react-router";
import {session} from "../service/session";
import {commands} from "../service/command/commands";
import {finalize} from "rxjs/operators";


const { Search } = Input;

const inputStyles = {
    caretColor: 'white',
    caretShape: 'block', // Doesn't actually work
    maxWidth: '200px',
    verticalAlign: 'middle',
    transition: 'max-width 0.5s',
}

const activeInputStyles = {
    maxWidth: '100%',
}

const TerminalInput = () => {
    const history = useHistory();
    const location = useLocation()

    const [active, setActive] = useState(false)
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)
    const inputRef = useRef<Input>(null)

    const runCommand = useCallback((input: string) => {
        setValue(input)
        setLoading(true)
        commands.submitCommand(input)
            .pipe(finalize(() => {setLoading(false)}))
            .subscribe({
                next: params => {


                    // Handle special commands
                    if ('HOME' === params.command) {
                        history.push('/'); setValue(""); return;
                    } else if ('SETTINGS' === params.command) {
                        history.push("/settings"); setValue(""); return;
                    } else if ('LOGOUT' === params.command) {
                        // clear URL so no endless loop
                        history.push('/');
                        session.endSession(); return;
                    }

                    // TODO - remove, TEMP
                    if (params.command === "COUNTER") {
                        history.push("/counter"); setValue(""); return;
                    }

                },
                error: (error) => {
                    message.error(error)
                }
            })
    }, [history])

    // Bind global command URL listener
    useEffect(() => {
        if (location.pathname.startsWith("/command/")) {
            const cmd = location.pathname.replace("/command/", "")
            runCommand(cmd)
        }
    }, [location, runCommand])

    // Bind global escape key listener
    useEffect(() => {
        const handleEsc = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                // Focus input if not, if already focused then remove text, if no text go to home page
                value ? setValue("") : (active ? runCommand('HOME') : inputRef.current!.focus())
            }
        }
        document.addEventListener("keydown", handleEsc, false)
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [active, history, runCommand, value]);

    return (
        <>
            <Search style={{...inputStyles,...active ? activeInputStyles: {}}}
                    ref={inputRef}
                    placeholder={">_"}
                    size="large"
                    onPressEnter={() => {runCommand(value)}}
                    onSearch={() => {runCommand(value)}}
                    value={value}
                    onChange={e => {setValue(e.target.value)}}
                    loading={loading}
                    allowClear={true}
                    maxLength={100}
                    // enterButton={<CodeOutlined />}
                    onBlur={() => setActive(false)}
                    onFocus={() => setActive(true)}
            />
        </>
    )

}

export default TerminalInput;